import { render, staticRenderFns } from "./DropdownSize.vue?vue&type=template&id=0bb77b63&"
import script from "./DropdownSize.vue?vue&type=script&lang=js&"
export * from "./DropdownSize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports